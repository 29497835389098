
import Vue from "vue";
import DatePicker from "@/components/v2/DatePicker.vue";
import LoadingCircle from "@/components/v2/LoadingCircle.vue";
import { ActivityLevelDTO, SignUpAPI, SignUpDataDTO, SignUpFormRemoteData } from "@/api/v2/sign-up";
import { PetAPI } from "@/api/v2/pet";
import { validators } from "@/validators";
import PreregistrationCustomerService from "@/services/customer/preregistrationCustomer.service";
import { RouteNamesV2 } from "@/router/routeNames";

type ActivityLevelDisplay = ActivityLevelDTO & { disabled: boolean };

export default Vue.extend<any, any, any, any>({
  name: "CreatePet",
  components: { DatePicker, LoadingCircle },
  data() {
    return {
      submitEnabled: false,
      disableEditForExistingPet: false,
      waitingForResponse: false,
      weightRule: (v: number) => {
        if (!v) return true;
        if (v < 0.5) return "The minimum weight is 0.5 kg";
        return true;
      },
      petNameRules: [validators.required("Pet name")],
      practiceGroupRules: [validators.required("Practice group")],
      signUpData: new SignUpDataDTO(""),
      selectedPet: null,
      formRemoteData: {
        breeds: [],
        crossBreeds: [],
        activityLevels: [],
        minPetAgeDays: 0
      } as SignUpFormRemoteData,
      breedsDataSource: [],
      activityLevelsSource: [],
      calculatedLifeStage: "",
      calculatingLifeStage: false,
      practiceGroups: [],
      practiceGroupsFilter: "",
      practiceGroupSelectorDisabled: false,
      searchDelayTimer: null,
      apiError: ""
    };
  },
  methods: {
    async loadFormRemoteData() {
      this.selectedPet = +this.$route.params.petId;
      if (this.selectedPet) {
        this.disableEditForExistingPet = true;

        const petData = await PreregistrationCustomerService.getCustomersPartialPet(
          this.selectedPet
        );
        this.signUpData = new SignUpDataDTO(
          petData.name,
          petData.breed?.breedId || undefined,
          petData.dob || undefined,
          petData.weight || undefined,
          petData.gender || undefined,
          petData.neutered || undefined,
          petData.activityLevel || undefined
        );
      }
      this.formRemoteData = await SignUpAPI.getSignUpFormRemoteData();
      this.activityLevelsSource = this.formRemoteData.activityLevels.map(
        (item: ActivityLevelDTO) => {
          return { ...item, disabled: false } as ActivityLevelDisplay;
        }
      );
      this.breedsDataSource = [
        { header: "Crossbreeds", divider: true },
        ...this.formRemoteData.crossBreeds,
        { header: "Breeds", divider: true },
        ...this.formRemoteData.breeds
      ];
    },
    async submitForm() {
      this.apiError = "";
      this.submitEnabled = false;
      const validated = this.$refs.form.validate();
      if (validated) {
        try {
          this.waitingForResponse = true;
          if (!this.selectedPet) {
            await SignUpAPI.createPetSignUpData(this.customerId, this.signUpData);
          } else {
            await SignUpAPI.updatePetSignUpData(this.customerId, this.selectedPet, this.signUpData);
          }
          await this.$router.push({
            name: RouteNamesV2.THANK_YOU,
            query: { petName: this.signUpData.petName }
          });
        } catch (error) {
          const errorsDetails = Object.values(error.errors);
          // Can be extended for future implementations
          if (errorsDetails) {
            this.apiError = errorsDetails.join(" | ");
          } else {
            this.apiError = error.errors;
          }
        } finally {
          this.waitingForResponse = false;
        }
      }
      this.submitEnabled = true;
    },
    async refreshAvailableActivityLevels() {
      const dob = this.signUpData.dateOfBirth; // ISO 8601
      const breedId = this.signUpData.breedId;
      if (dob && breedId) {
        if (new Date(dob).getTime() >= Date.now()) {
          // future as a 'puppy' to keep an input disabled
          this.calculatedLifeStage = "puppy";
        } else {
          this.calculatingLifeStage = true;
          try {
            this.calculatedLifeStage = await PetAPI.getCurrentLifeStage(dob, breedId);
          } finally {
            this.calculatingLifeStage = false;
          }
        }
      }
      const highlyActive = this.activityLevelsSource.find(
        (item: ActivityLevelDisplay) => item.identifier === "highly-active"
      );
      highlyActive.disabled = this.calculatedLifeStage === "puppy";
      if (this.signUpData.activityLevel === highlyActive.identifier) {
        this.signUpData.activityLevel = undefined;
      }
    }
  },
  computed: {
    maxDateOfBirth() {
      const date = new Date();
      date.setDate(date.getDate() - this.formRemoteData.minPetAgeDays);
      return date;
    }
  },
  watch: {
    "signUpData.weight"(newVal) {
      if (!newVal) {
        this.signUpData.weight = undefined;
      } else {
        this.signUpData.weight = newVal;
      }
    },
    "signUpData.breedId"() {
      this.refreshAvailableActivityLevels();
    },
    "signUpData.dateOfBirth"() {
      this.refreshAvailableActivityLevels();
    }
  },
  async mounted() {
    await this.loadFormRemoteData();
  }
});
